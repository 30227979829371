<template>
	<v-container>
		<AdminDefaultHeader>{{ userName }}'s Dashboard</AdminDefaultHeader>
		<StudentCoursesTabs></StudentCoursesTabs>
	</v-container>
</template>

<script>
import StudentCoursesTabs from "../courses/StudentCoursesTabs.vue";
import AdminDefaultHeader from "../../../../components/AdminDefaultHeader.vue";
import { mapState } from "vuex";

export default {
	name: "StudentDashboard",
	components: {
		AdminDefaultHeader,
		StudentCoursesTabs
	},
	data() {
		return {};
	},

	computed: {
		...mapState({
			userName: state => state.user.firstName
		})
	}
};
</script>
